@import "../../assets/styles/base";

.text__main {
  color: $gray;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  &--disabled {
    color: $medium-gray;
  }
}

.text_card_subtitle {
  font-weight: bold;
}

.subtitle-with-color{
  color: $aquamarine;
  margin: 0;
}

.text-no-margin{
  margin: 0;
}