@import 'variables';
@import 'utils';
@import 'responsive';

html {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

*::before,
*::after {
  box-sizing: border-box;
}

button * {
  pointer-events: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px white inset !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-lato;
  font-weight: $font-regular;
}

.wrap {
  max-width: 1240px;
  margin: 0 auto;
}

.content {
  padding: 0 width-column(2, 26);
  @include clearfix;
  @include tablet { padding: 0 width-column(1, 31); }
}

.hideblock {
  overflow: hidden;
  height: 0;
  z-index: -1;
}



.loader {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background: url('../../images/loading-red.svg') no-repeat 0 0;
  background-size: 100% auto;
  animation-name: rotater;
  animation-duration: 0.9s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rotater {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
