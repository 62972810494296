@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

body {
    margin: 0;
}

*,
.MuiTypography-root,
.MuiListItemText-primary,
.MuiTypography-body1 {
    font-family: 'Montserrat', sans-serif;
}

#react-simple-modal-container {
  .modal__main {
    min-height: auto !important;

    .rsm-flex-1 {
      padding: 0;
    }
  } 
}
