//fonts
$font-lato: 'lato', Helvetica, Arial;
$font-roboto: 'roboto', Helvetica, Arial;

// font weights
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 600;

//colors
$gray1: #494F66;
$gray2: #676F8F;
$gray3: #A9B1D1;
$gray4: #EDEFF5;
$gray5: #FAFAFC;
$gray6: #D4D5E8;
$gray7: #C1C6DB;
$gray8: #6E7799;
$lila1: #6F7DFF;
$lila2: #939DFF;
$red: #EF3340;
$red2: #EF3340;
$red3: #EF3340;
$red4: #FC4E55;
$red5: #FF0000;
$pink: #09489B;
$pink2: #09489B;
$pink3: #FFD7DA;
$pink4: #FEE7E9;

$green: #92D66F;
$green2: #CFEDC0;
$green3: #E9F7E2;
$green4: #83CC5E;
$orange: #FFAF3A;
$orange2: #FFEFD8;
$orange3: #FFA733;



// COLORS
$border-gray:#BDBDBD;
$black: #000000;
$dark-blue: #0A1E43;
$dark-gray: #929292;
$gray: #4F4F4F;
$green: #00AA80;
$principal:#C21000;
$ligh-green: #2CAD6E;
$light-blue: #00A0DF;
$light-gray: #E0E0E0;
$light-white: #FAFAFA;
$medium-gray: #828282;
$orange: #D55C38;
$pink: #CB4089;
$purple: #330033;
$violet: #9431C2;
$yellow: #FFFF00;
$white: #FFFFFF;
$aquamarine:#25B6BF;

$main: $principal;
$secondary: $aquamarine;

// FONT WEIGHT
$weight-extrabold: 600;
$weight-bold: 500;
$weight-medium: 400;
$weight-light: 300;

//BREAKPOINTS
$sm: 320px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

//Tenant variables
$tenant_font_family: var(--tenant_font_family);

$tenant_main_background_color: var(--tenant_main_background_color);
$tenant_secondary_background_color: var(--tenant_secondary_background_color);
$tenant_main_text_color: var(--tenant_main_text_color);
$tenant_secondary_text_color: var(--tenant_secondary_text_color);

$tenant_disabled_main_background_color: var(--tenant_disabled_main_background_color);
$tenant_disabled_secondary_background_color: var(--tenant_disabled_secondary_background_color);
$tenant_disabled_main_text_color: var(--tenant_disabled_main_text_color);
$tenant_disabled_secondary_text_color: var(--tenant_disabled_secondary_text_color);
